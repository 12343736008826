/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
/*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*/
  


import state from './modulePoliciesManagementState.js'
import mutations from './modulePoliciesManagementMutations.js'
import actions from './modulePoliciesManagementActions.js'
import getters from './modulePoliciesManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

